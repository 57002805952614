export default [
  {
    path: 'policies/upload-requirements',
    name: 'whatsapp.policies.upload-requirements',
    component: () => import('@/views/whatsapp/PurchaseForm.vue'),
  },
  {
    path: 'policies/extra-info',
    name: 'whatsapp.policies.customFields',
    component: () => import('@/views/whatsapp/PurchaseCustomFields.vue'),
  },
  {
    path: 'claims/upload-requirements',
    name: 'whatsapp.claims.upload-requirements',
    component: () => import('@/views/whatsapp/ClaimForm.vue'),
  },
  {
    path: 'claims/extra-info',
    name: 'whatsapp.claims.customFields',
    component: () => import('@/views/whatsapp/ClaimCustomFields.vue'),
  }
];
