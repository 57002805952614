import { Axios } from '@/network/axios';

export function getSides(config = {}) {
  return Axios.get('/v1/vehicles/sides', config);
}

export function getDamageTypes(config = {}) {
  return Axios.get('/v1/vehicles/damage-types', config);
}

export function getParts(config = {}) {
  return Axios.get('/v1/vehicles/types', config);
}

export function getBrands(config = {}) {
  return Axios.get('/v1/vehicles/brands', config);
}

export function getVehicleModels(brandId: number, config = {}) {
  return Axios.get(`/v1/vehicles/brands/${brandId}/models`, config);
}

export default {
  getSides,
  getParts,
  getDamageTypes,
  getBrands,
  getVehicleModels,
};
