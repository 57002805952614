import { type AxiosRequestConfig } from 'axios';

export const SYNC_ID_HEADER = 'X-Request-Sync-Id';
export const QUEUED_REQUEST_HEADER = 'X-Request-Queued-Offline';
export const SESSION_ID_HEADER = 'X-Curacel-Auto-Session-Id';

export function getQueueableRequestConfig(config: AxiosRequestConfig = {}) {
  if (!config.headers) {
    config.headers = {} as AxiosRequestConfig['headers'];
  }
  config.headers![QUEUED_REQUEST_HEADER] = '1';
  return config;
}

export function queueable(headers: Record<string, string> | Headers): boolean {
  return headers instanceof Headers
    ? headers.has(SYNC_ID_HEADER)
    : typeof headers[SYNC_ID_HEADER] == 'string';
}

export function isNetworkError(error: any) {
  return error.code === 'ERR_NETWORK';
}

/**
 * Let the API know that the request failed and is being retried
 */
export async function getQueueableRequest(request: Request) {
  const headers = new Headers(request.headers);
  headers.append(QUEUED_REQUEST_HEADER, '1');

  let body;

  if (request.method !== 'GET' && request.method !== 'HEAD') {
    body = await request.clone().arrayBuffer();
  }

  return new Request(request.url, {
    body,
    headers,
    mode: request.mode,
    cache: request.cache,
    method: request.method,
    redirect: request.redirect,
    referrer: request.referrer,
    integrity: request.integrity,
    keepalive: request.keepalive,
    credentials: request.credentials,
  });
}
