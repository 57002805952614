import { defineStore } from 'pinia';

export const useLoadingStore = defineStore('loader', {
  state: () => ({ loading: false }),
  getters: {
    getLoadingState: (state) => state.loading,
  },
  actions: {
    toggleLoader(payload: { loading: boolean }) {
      this.loading = payload.loading;
    },
  },
});
