import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useStorage } from '@vueuse/core';
import agentRoutes from './agents';
import { useInsurerStore } from '@/stores/insurer';

export default [
  {
    path: '',
    name: 'insurer.home',
    component: () => import('@/views/HomeView.vue'),
    meta: {
      title: 'Dashboard',
    },
  },

  // Agents
  ...agentRoutes,

  {
    path: 'vehicle-validation',
    name: 'insurer.vehicle-validation',
    component: () => import('@/views/vehicle-assessment/index.vue'),
    meta: {
      title: 'Vehicle Assessment',
    },
  },

  // POLICY PURCHASE
  // 1. comprehensive
  {
    path: 'policies/plan',
    name: 'insurer.chooseInsurancePlan',
    component: () => import('@/views/policy/ChooseInsurancePlan.vue'),
    meta: {
      title: 'Purchase Policy',
    },
    beforeEnter(
      to: RouteLocationNormalized,
      _from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) {
      const { insurer } = storeToRefs(useInsurerStore());

      if (insurer?.value?.third_party_policy_enabled) {
        next();
      } else {
        next({ name: 'insurer.products', query: { plan: 'comprehensive' } });
      }
    },
  },
  {
    path: 'policies/products',
    name: 'insurer.products',
    component: () => import('@/views/products/index.vue'),
    meta: {
      title: 'Choose Policy Product',
    },
    beforeEnter(
      to: RouteLocationNormalized,
      _from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) {
      if (!to.query?.plan) {
        next({ name: 'insurer.chooseInsurancePlan', params: to.params });
      } else next();
    },
  },
  {
    path: 'policies/comprehensive/requirements',
    name: 'insurer.inspectionRequirements',
    component: () => import('@/views/InspectionRequirements.vue'),
    meta: {
      title: 'Comprehensive Policy',
    },
  },
  {
    path: 'policies/comprehensive/instructions',
    name: 'insurer.inspectionSteps',
    component: () => import('@/views/InspectionInstructions.vue'),
    meta: {
      title: 'Comprehensive Policy',
    },
  },
  {
    path: 'policies/comprehensive/uploads',
    name: 'insurer.uploadOptions',
    component: () => import('@/views/InspectionUploads.vue'),
    meta: {
      title: 'Vehicle Inspection',
    },
  },
  {
    path: 'policies/document/uploads',
    name: 'insurer.documents.upload',
    component: () => import('@/views/document-upload/index.vue'),
    meta: {
      title: 'Document Uploads',
    },
  },
  {
    path: 'policies/comprehensive/inspection-form',
    name: 'insurer.inspectionForm',
    component: () => import('@/views/InspectionForm.vue'),
    meta: {
      title: 'Vehicle Inspection',
    },
    beforeEnter(
      to: RouteLocationNormalized,
      _from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) {
      const documents = useStorage('uploaded-documents', {});

      if (!documents || Object.keys(documents.value).length === 0) {
        next({ name: 'insurer.documents.upload', params: to.params });
      } else next();
    },
  },
  {
    path: 'buyPolicy',
    name: 'insurer.buyPolicy',
    component: () => import('@/views/BuyPolicy.vue'),
    meta: {
      title: 'Policy Purchase',
    },
  },
  {
    path: 'view-active-policies',
    name: 'insurer.viewActivePlans',
    component: () => import('@/views/policy/ViewActivePlans.vue'),
    meta: {
      title: 'View Active Policies',
    },
  },
  {
    path: 'insuranceReview',
    name: 'insurer.insuranceReview',
    component: () => import('@/views/InsuranceReview.vue'),
    meta: {
      title: 'Policy Purchase',
    },
  },
  {
    path: 'makePayment',
    name: 'insurer.makePayment',
    component: () => import('@/views/MakePayment.vue'),
    meta: {
      title: 'Policy Purchase',
    },
  },
  {
    path: 'policySuccess',
    name: 'insurer.policySuccess',
    component: () => import('@/views/PolicySuccess.vue'),
    meta: {
      title: 'Policy Purchase',
    },
  },
  {
    // NB: this path is referenced by API
    path: ':evaluationId/pending-docs',
    name: 'insurer.pendingDocs',
    component: () => import('@/views/document-upload/PendingDoc.vue'),
    meta: {
      title: 'Upload Missing Documents',
    },
  },

  // CLAIMS
  {
    path: 'claimsIntro',
    name: 'insurer.claimsIntro',
    component: () => import('@/views/claims/ClaimsStepOne.vue'),
    beforeEnter(
      to: RouteLocationNormalized,
      _from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) {
      if (to.query.transactionId) {
        next({ name: 'insurer.claims.requirements', query: to.query });
      } else {
        next();
      }
    },
    meta: {
      title: 'Insurance Claims',
    },
  },
  {
    path: ':user/claims',
    name: 'insurer.claims.list',
    props: true,
    component: () => import('@/views/claims/ClaimsList.vue'),
    meta: {
      title: 'Claims List',
    },
  },
  {
    path: ':user/claims/:claimId',
    name: 'insurer.claims.show',
    props: true,
    component: () => import('@/views/claims/ShowClaim.vue'),
    meta: {
      title: 'Claim Details',
    },
  },
  {
    path: ':user/claims/:claimId/resolve-options',
    name: 'insurer.claims.resolve-options',
    props: true,
    component: () => import('@/views/claims/ResolveOptions.vue'),
    meta: {
      title: 'Resolve Claim Options',
    },
  },
  {
    path: ':user/claims/:claimId/dispute',
    name: 'insurer.claims.dispute',
    props: true,
    component: () => import('@/views/claims/DisputeClaim.vue'),
    meta: {
      title: 'Dispute Claim Details',
    },
  },
  {
    path: ':user/claims/:claimId/dispute-thread',
    name: 'insurer.claims.dispute-thread',
    props: true,
    component: () => import('@/views/claims/DisputeThread.vue'),
    meta: {
      title: 'Dispute Activity Thread',
    },
  },
  {
    path: 'claims/create',
    name: 'insurer.claims.create',
    component: () => import('@/views/claims/ClaimsForm.vue'),
    meta: {
      title: 'Insurance Claims',
    },
  },
  {
    path: 'claims/create/extra-info',
    name: 'insurer.claims.create.custom-fields',
    component: () => import('@/views/claims/CustomFields.vue'),
    meta: {
      title: 'Insurance Claims',
    },
  },
  {
    path: 'collectionSteps',
    name: 'insurer.collectionSteps',
    component: () => import('@/views/claims/CollectionSteps.vue'),
    meta: {
      requiresClaimSession: true,
      title: 'Insurance Claims',
    },
  },
  {
    path: 'claims/requirements',
    name: 'insurer.claims.requirements',
    component: () => import('@/views/claims/ClaimsRequirements.vue'),
    meta: {
      title: 'File a claim',
    },
  },
  {
    path: 'claims/instructions',
    name: 'insurer.claims.instructions',
    component: () => import('@/views/claims/ClaimsInstructions.vue'),
    meta: {
      title: 'Submit a claim',
    },
  },
  {
    path: 'collectionUploads',
    name: 'insurer.collectionUploads',
    component: () => import('@/views/claims/CollectionUploads.vue'),
    meta: {
      requiresClaimSession: true,
      title: 'Insurance Claims',
    },
  },
  {
    path: 'quotes',
    name: 'insurer.addQuotes',
    component: () => import('@/views/claims/AddQuotes.vue'),
    meta: {
      requiresClaimSession: true,
      title: 'Insurance Claims',
    },
  },
  {
    path: 'documents',
    name: 'insurer.documentUpload',
    component: () => import('@/views/claims/DocumentUpload.vue'),
    meta: {
      requiresClaimSession: true,
      title: 'Insurance Claims',
    },
  },
  {
    path: 'bank-details',
    name: 'insurer.bankDetails',
    component: () => import('@/views/claims/BankAccountInfo.vue'),
    meta: {
      requiresClaimSession: true,
      title: 'Insurance Claims',
    },
  },
  {
    path: 'collectionReview',
    name: 'insurer.collectionReview',
    component: () => import('@/views/claims/CollectionReview.vue'),
    meta: {
      requiresClaimSession: true,
      title: 'Insurance Claims',
    },
  },
  {
    path: 'claimsSuccess',
    name: 'insurer.claimsSuccess',
    component: () => import('@/views/claims/ClaimsSuccess.vue'),
    meta: {
      title: 'Insurance Claims',
    },
  },

  // THIRD PARTY POLICY
  {
    path: 'policy/thirdParty',
    name: 'insurer.thirdParty',
    component: () => import('@/views/policy/thirdParty/Index.vue'),
    meta: {
      title: 'Purchase Policy',
    },
    beforeEnter(
      to: RouteLocationNormalized,
      _from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) {
      const { insurer } = storeToRefs(useInsurerStore());

      const documents = useStorage('uploaded-documents', {});

      if (!insurer?.value?.third_party_policy_enabled) {
        next({
          name: 'insurer.home',
          params: { insurer: to.params?.insurer },
        });
      } else if (!documents || Object.keys(documents.value).length === 0) {
        next({ name: 'insurer.documents.upload', params: to.params });
      } else {
        next();
      }
    },
  },
  {
    path: 'policy/thirdParty/requirements',
    name: 'insurer.thirdParty.requirements',
    component: () =>
      import('@/views/policy/thirdParty/ThirdPartyRequirements.vue'),
    meta: {
      title: 'Third party Policy',
    },
  },

  // fleets
  {
    path: 'fleets/:code/vehicles',
    name: 'fleets.vehicles',
    component: () => import('@/views/fleets/vehicles.vue'),
    meta: {
      title: 'Inspect Vehicles',
    },
  },
];
