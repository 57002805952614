<script setup lang="ts">
import { onBeforeMount } from 'vue';
import { getBrands } from './services/vehicles';
import Loader from '@/components/Loader.vue';
import { Toaster } from '@/components/ui/sonner';

defineOptions({ name: 'App' });

onBeforeMount(() => {
  getBrands()
    .then((data) => {
      useInsurerStore().setVehicleBrands(data.data.data);
    })
    .catch((error) => console.error(error));
});
</script>

<template>
  <div id="app">
    <!-- <ReloadPrompt /> -->
    <Toaster />
    <Loader />
    <RouterView />
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Outfit');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, Outfit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#body {
  background: url('./assets/background.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
