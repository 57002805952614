import { v4 as uuidv4 } from 'uuid';
import axios, {
  type AxiosRequestHeaders,
  type InternalAxiosRequestConfig,
} from 'axios';
import { captureException } from '@sentry/vue';
import { useInsurerStore } from '@/stores/insurer';
import { SYNC_ID_HEADER } from '@/helpers/requestQueue';

const Axios = axios.create({
  // IMPORTANT:
  // Do not set the content type to application/json as it will cause file uploads to fail
  // It's better to let axios set the content type automatically
  baseURL: import.meta.env.VITE_APP_API_URL,
  // adapter: 'fetch',
});

Axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    try {
      const store = useInsurerStore();
      const token = store.getInsurer?.code as string | null;

      if (token) {
        config.headers!['X-Insurer'] = token;
        config.params = {
          insurer: token,
          ...config.params,
        };
      }
    } catch (e) {
      captureException(e);
    }

    return addRequestId(config);
  },
  (error) => {
    captureException(error);
    return Promise.reject(error);
  }
);

function addRequestId(config: InternalAxiosRequestConfig) {
  if (config?.headers && config?.headers[SYNC_ID_HEADER]) {
    return config;
  }

  if (!config?.headers) {
    config!.headers = {} as AxiosRequestHeaders;
  }

  config!.headers![SYNC_ID_HEADER] = uuidv4();

  return config;
}

export { Axios };

export default Axios;
