import { toast } from 'vue-sonner';

const showError = function (title: string, message: any = '') {
  if (message?.isAxiosError && message?.response) {
    message = message.response.data;
  }

  let msg = message?.message || message;

  if (msg.constructor === Array || msg.constructor === Object) {
    msg = Object.values(msg)[0];
  }

  if (message.errors) {
    msg += '<br/>' + Object.values(message.errors)[0];
  }

  toast.error(title, { description: msg });
};

const showSuccess = function (
  title: string,
  message: any = '',
  use_toast = true
) {
  if (message.message) message = message.message;
  if (message.data && message.data.message) message = message.data.message;

  if (message.constructor === Array || message.constructor === Object) {
    message = Object.values(message).join('<br/>');
  }

  toast.success(title, { description: message });
};

export { showError, showSuccess };
