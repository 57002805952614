import { nextTick } from 'vue';
import {
  type NavigationGuardNext,
  type RouteLocationNormalized,
  createRouter,
  createWebHistory,
} from 'vue-router';
import insurerRoutes from './insurer';
import whatsappRoutes from './whatsapp';
import { useLoadingStore } from '@/stores/loader';
import { useUserStore } from '@/stores/user';
import { showError } from '@/helpers/alerts';
import { useInsurerStore } from '@/stores/insurer';
import Collections from '@/services/collection';
import { useClaimsSession } from '@/composables/useClaimsSession';

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: '/profiles/curacel',
    },
    {
      path: '/profiles/:insurer',
      component: () => import('@/views/InsurerProfiles.vue'),
      children: insurerRoutes,
      props: true,
      async beforeEnter(
        to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        next: NavigationGuardNext
      ) {
        try {
          const resp = await useInsurerStore().setInsurer(
            to.params.insurer as string
          );
          localStorage.setItem(
            'allow_estimates',
            resp.data.data.allow_repair_estimates
          );
          next();
        } catch (e: any) {
          if (e.response && e.response.status == 404) {
            next({
              name: '404',
              params: { pathMatch: to.path.split('/').slice(1) },
            });
          } else {
            console.error(e);
          }
        }
      },
    },
    {
      path: '/whatsapp/:session',
      component: () => import('@/views/whatsapp/Layout.vue'),
      children: whatsappRoutes,
      async beforeEnter(to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
        try {
          const resp = await useInsurerStore().setInsurer(to.params.session as string, { channel: 'whatsapp' });
          localStorage.setItem('allow_estimates', resp.data.data.allow_repair_estimates);
          next();
        } catch (e: any) {
          if (e.response && e.response.status === 404) {
            next({
              name: '404',
              params: { pathMatch: to.path.split('/').slice(1) },
            });
          } else {
            console.error(e);
          }
        }
      },
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import('@/views/404.vue'),
      name: '404',
    },
  ],
});

router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const store = useLoadingStore();
    const user = useUserStore();
    const { removeSession, validateSession } = useClaimsSession();

    store.toggleLoader({ loading: true });

    if (to.query.transactionId && !user.transactionEvaluation) {
      try {
        const insurer = useInsurerStore().getInsurer?.code || to.params.insurer;

        const { data } = await Collections.verifyTransaction(
          to.query.transactionId as string,
          { insurer }
        );

        if (data.evaluation) {
          user.setTransactionEvaluation(data.evaluation);
          localStorage.setItem('id', data.evaluation.id);
          localStorage.removeItem('policy_id');
        } else {
          throw new Error('Transaction not found');
        }
      } catch (error) {
        console.error(error);
        next(false);
        return;
      }
    }

    if (
      to?.meta?.requiresClaimSession &&
      !validateSession(to.params.insurer as string)
    ) {
      removeSession();
      next({ name: 'insurer.claimsIntro', params: to.params });
      showError(
        'Session Expired',
        'Your session has expired. Please start a new claim session.'
      );
      return;
    }

    if (to?.meta?.requiresUploadedImages) {
      try {
        let images = localStorage.getItem('images');
        images = JSON.parse(images as string);
        if (!images) {
          throw new Error('No images found');
        }
      } catch (error) {
        next({ name: 'insurer.agent.userVehicleUploads', params: to.params });
        return;
      }
    }

    if (!to.query.userId && user.getUserId) {
      next({ ...to, query: { ...to.query, userId: user.getUserId } });
    } else if (!to.query.transactionId && user.transactionEvaluation?.user_id) {
      next({
        ...to,
        query: {
          ...to.query,
          transactionId: user.transactionEvaluation.user_id,
        },
      });
    } else {
      next();
    }
  }
);

router.afterEach(async (to: RouteLocationNormalized) => {
  // NB: execute this first before any async operation
  const user = useUserStore();
  if (to.query.userId && !user.getUserId) {
    user.setUserId((to.query.userId as string) ?? null);
  }

  await nextTick(() => {
    const loader = useLoadingStore();
    loader.toggleLoader({ loading: false });
  });
});

export default router;
