<template>
  <div v-if="getLoadingState" class="loader">
    <img src="/assets/curacel-loader.gif" alt="Loading..." />
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useLoadingStore } from '@/stores/loader';

export default {
  name: 'TransitionLoader',
  computed: {
    ...mapState(useLoadingStore, ['getLoadingState']),
  },
};
</script>

<style scoped>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(255, 255, 255, 0.8); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  backdrop-filter: blur(10px);
  z-index: 1000;
}

.loader img {
  width: 100%;
  height: 100%;
}
</style>
