export default [
  {
    path: 'agent',
    name: 'insurer.agent',
    component: () => import('@/views/agents/Home.vue'),
    meta: {
      title: 'Agents Portal',
    },
  },
  {
    path: 'agent/inspections/vehicle-info',
    name: 'insurer.agent.vehicleInfo',
    component: () => import('@/views/agents/VehicleInfo.vue'),
    meta: {
      title: 'Agents Portal',
    },
  },
  {
    path: 'agent/inspections/vehicle-uploads',
    name: 'insurer.agent.vehicleUploads',
    component: () => import('@/views/agents/VehicleUploads.vue'),
    meta: {
      title: 'Agent Vehicle Inspection',
    },
  },
  {
    path: 'agent/inspections/completed',
    name: 'insurer.agent.inspection-completed',
    component: () => import('@/views/agents/InspectionCompleted.vue'),
    meta: {
      title: 'Thank You!',
    },
  },

  // customer flow
  {
    // NB: this path is referenced in the API.
    // Always ensure that the path is updated in the API
    // Check: generateAgentCustomerAssessmentLink
    path: 'agent/user-inspections/:evaluationId/vehicle-uploads',
    name: 'insurer.agent.userVehicleUploads',
    component: () => import('@/views/agents/UserVehicleUploads.vue'),
    meta: {
      title: 'User Vehicle Inspection',
    },
  },
  {
    path: 'agent/user-inspections/:evaluationId/vehicle-info',
    name: 'insurer.agent.userVehicleInfo',
    component: () => import('@/views/agents/UserVehicleInfo.vue'),
    meta: {
      title: 'User Vehicle Inspection',
      requiresUploadedImages: true,
    },
  },
  {
    path: 'agent/user-inspections/:evaluationId/completed',
    name: 'insurer.agent.userInspectionCompleted',
    component: () => import('@/views/agents/UserInspectionCompleted.vue'),
    meta: {
      title: 'Thank You!',
      requiresUploadedImages: true
    },
  },
];
