import { defineStore } from 'pinia';
import axios from 'axios';

interface Insurer {
  id: number;
  name: string;
  code: string;
  email: string;
  currency: string;
  country?: string;
  country_iso_code?: string;
  allow_repair_estimates: boolean;
  third_party_policy_enabled?: boolean;
}

const Axios = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  headers: { 'Content-Type': 'application/json' },
});

export const useInsurerStore = defineStore('insurer', {
  state: (): {
    insurer: Insurer | null;
    vehicleBrands: Array<{
      id: number;
      name: string;
      countries?: Array<string>;
    }>;
  } => ({ insurer: null, vehicleBrands: [] }),
  getters: {
    getInsurer: (state) => state.insurer,
    getVehicleBrands: (state) => state.vehicleBrands,
  },
  actions: {
    async setInsurer(code: string, params?: any) {
      const response = await Axios.get(`/insurer/${code}`, { params });
      this.insurer = response.data.data;
      return response;
    },
    setVehicleBrands(
      brands: Array<{ id: number; name: string; countries: Array<string> }>
    ) {
      this.vehicleBrands = brands;
    },
  },
});
