import { defineStore } from 'pinia';

type UserState = {
  user: Record<string, any> | null;
  userId: string | null;
  transactionEvaluation: Record<string, any> | null;
};

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    user: null,
    userId: null,
    transactionEvaluation: null,
  }),
  getters: {
    getUserId: (state) => state.userId,
    getUser: (state) => state.user,
  },
  actions: {
    setUserId(id: UserState['userId']) {
      this.userId = id;
    },
    setUser(user: UserState['user']) {
      this.user = user;
    },
    setTransactionEvaluation(evaluation: UserState['transactionEvaluation']) {
      this.transactionEvaluation = evaluation;
    },
  },
});
