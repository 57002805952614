import { v4 as uuidv4 } from 'uuid';
import { useRoute } from 'vue-router';

export function useClaimsSession() {
  const route = useRoute();

  const SESSION_NAME = 'claims_session_id';

  function startSession() {
    let id = localStorage.getItem(SESSION_NAME);

    if (!id) {
      id = `${uuidv4()}_-_${route.params.insurer}`;
      localStorage.setItem(SESSION_NAME, id);
    }

    return id;
  }

  function sessionStarted() {
    const id = localStorage.getItem(SESSION_NAME);
    return id !== '' && typeof id === 'string';
  }

  function validateSession(insurerCode: string) {
    const id = localStorage.getItem(SESSION_NAME);
    return id && insurerCode === id.split('_-_')[1];
  }

  function removeSession() {
    localStorage.removeItem(SESSION_NAME);
  }

  function getSessionId() {
    const id = localStorage.getItem(SESSION_NAME);

    if (id) {
      return id;
    }

    throw new Error('Session not started');
  }

  return {
    getSessionId,
    removeSession,
    startSession,
    sessionStarted,
    validateSession
  };
}
